import { useEffect } from "react";

const useRedealCornerWidget = () => {
  useEffect(() => {
    // console.log("[useRedeal] Hook mounted");

    const triggerRedeal = () => {
      if (typeof window.redeal === "function") {
        // console.log("[useRedeal] Redeal is ready. Triggering 'cornerwidget'...");
        window.redeal("cornerwidget");
      } else {
        // console.log("[useRedeal] Redeal function not available yet.");
      }
    };

    // Check if script is already present
    const scriptExists = document.querySelector('script[src="https://static.redeal.se/widget/js/redeal.js"]');

    if (scriptExists) {
      // console.log("[useRedeal] Redeal script found. Attempting to trigger...");
      triggerRedeal();
    } else {
      // console.log("[useRedeal] Redeal script NOT found. Watching for script load...");
      
      // Watch for script being added dynamically
      const observer = new MutationObserver(() => {
        if (document.querySelector('script[src="https://static.redeal.se/widget/js/redeal.js"]')) {
          // console.log("[useRedeal] Redeal script detected. Attempting to trigger...");
          triggerRedeal();
          observer.disconnect(); // Stop observing after the script is found
        }
      });

      observer.observe(document.head || document.body, { childList: true, subtree: true });

      return () => {
        observer.disconnect(); // Cleanup observer on unmount
        if (typeof window.redealHide === "function") {
          // console.log("[useRedeal] Unmounting: Hiding Redeal widget...");
          window.redealHide();
        } else {
          // console.log("[useRedeal] Unmounting: RedealHide not available.");
        }
      };
    }
  }, []); // No unnecessary re-renders

  return null; // No UI needed, this is just a hook
};

export default useRedealCornerWidget;
