import React, { useEffect, useContext } from 'react'
import { styled } from 'linaria/react'

import CartProvider from '@jetshop/core/components/Query/CartProvider'
import cartQuery from '../Cart/CartQuery.gql'
import t from '@jetshop/intl'

import {
   HELLO_RETAIL_SETTINGS,
   READ_MORE,
} from '../CustomElements/elementTypes'
import getParentOrCanonicalRoute from '@jetshop/ui/Breadcrumbs/getParentsOrCanonicalRoute'
import { useSiteSettingsQuery } from '../../hooks/useSiteSettings'
import loadable from '@loadable/component'
import LoadingPage from '../LoadingPage'
import HelloRetailCarousel from '../HelloRetail/Carousel'
import * as HelloRetailConst from '../HelloRetail/Constants'
import {
   getCategoryHierarchies,
   getHelloRetailProducts,
   HelloRetailHelper,
} from '../HelloRetail/HelloRetailHelper'
import { SiteContext } from '../Global/SiteContext'
import { UseHelloRetail } from '../HelloRetail/UseHelloRetail'
import { ACTIVATE_ACCOUNT_PAGE_ID, BRAND_PAGE_ID, RESTORATION_SHOW_PAGE_ID, WORK_FOR_US_PAGE_ID } from '../Core/Util/Constants'
import { HelloRetailCarouselFetcher } from '../HelloRetail/HelloRetailCarouselFetcher'
import { getDeepestValueFromHierarchy } from '../Core/Util/Helpers'
import useRedealCornerWidget from '../Voyado/hooks/useRedealCornerWidget'

const HelloRetailWrapper = styled.div`
   > div:nth-child(odd) {
      background-color: #d4d4d4;
   }
`

const LoadableBrandsPage = loadable(() => import('../Pages/Brands/Brands'), {
   fallback: <LoadingPage />,
})

const LoadableBrandPage = loadable(
   () => import('../Pages/Brands/BrandSingle'),
   {
      fallback: <LoadingPage />,
   }
)

const LoadableCategoryStandardPage = loadable(
   () => import('../Pages/CategoryStandard/CategoryStandard'),
   {
      fallback: <LoadingPage />,
   }
)

const LoadableNewsletterRegistrationPage = loadable(
   () => import('../Pages/Newsletter/NewsletterRegistrationPage'),
   {
      fallback: <LoadingPage />,
   }
)

const LoadableNewsletterRegistrationPageV2 = loadable(
   () => import('../Pages/Newsletter/NewsletterRegistrationPageV2'),
   {
      fallback: <LoadingPage />,
   }
)

const LoadableNewsletterWelcomePage = loadable(
   () => import('../Pages/Newsletter/NewsletterWelcomePage'),
   {
      fallback: <LoadingPage />,
   }
)

const LoadableNewsletterUnregisterPage = loadable(
   () => import('../Pages/Newsletter/NewsletterUnregisterPage'),
   {
      fallback: <LoadingPage />,
   }
)

const LoadableActivateAccountPage = loadable(
   () => import('../Pages/Newsletter/ActivateAccountPage'),
   {
      fallback: <LoadingPage />,
   }
)

const LoadableBlogPage = loadable(() => import('../Pages/Blog/BlogLanding'), {
   fallback: <LoadingPage />,
})

const LoadableCategoryBlogPage = loadable(() => import('../Pages/Blog/BlogCategory'), {
   fallback: <LoadingPage />,
})

const LoadableBlogArticlePage = loadable(() => import('../Pages/Blog/BlogArticle'), {
   fallback: <LoadingPage />,
})

const LoadableStorePage = loadable(() => import('../Pages/Store/StoreCategory'), {
   fallback: <LoadingPage />,
})


const CategoryPage = ({ category, ...props }) => {
   const [getSetting, loadingSettings] = useSiteSettingsQuery()
   const { showVat, setBreadCrumbData } = useContext(SiteContext)

   const setting = getSetting(null, HELLO_RETAIL_SETTINGS)
   const BEST_SELLER_CATEGORY = HelloRetailHelper(
      'BEST_SELLER_CATEGORY',
      setting
   )
   const LATEST_VISITED = HelloRetailHelper('LATEST_VISITED', setting)

   const parents = getParentOrCanonicalRoute(
      props?.result?.data?.route?.parents,
      category
   )

   const helloRetailHierarchies = getCategoryHierarchies(parents, category)

   const { helloRetailRecoms, helloRetailLoading } = UseHelloRetail(
      [BEST_SELLER_CATEGORY, LATEST_VISITED],
      helloRetailHierarchies
   )

   const isHelloRetailAllowed = !HelloRetailConst.CATEGORY_FILTER.includes(
      category?.id
   )

   const items = category?.data?.items

   /* Split custom elements (items) at readMore block
     and display above and below category grid content */
   const readMoreIndex = items?.findIndex(item => item.type === READ_MORE)

   let itemsAbove
   let itemsBelow
   let showReadMore

   const isBrandCategory = BRAND_PAGE_ID === category?.id
   const isSingleBrandCategory = category?.parent?.id == BRAND_PAGE_ID
   const isWorkForUsCategory = category?.id == WORK_FOR_US_PAGE_ID || getDeepestValueFromHierarchy(category, 'parent', 'id') == WORK_FOR_US_PAGE_ID
   const isRestorationShowCategory = category?.id == RESTORATION_SHOW_PAGE_ID || getDeepestValueFromHierarchy(category, 'parent', 'id') == RESTORATION_SHOW_PAGE_ID 

   if (items && readMoreIndex > 0) {
      itemsAbove = items.slice(0, readMoreIndex)
      itemsBelow = items.slice(readMoreIndex + 1, items.length)
      showReadMore = false
   } else {
      itemsAbove = items
      itemsBelow = null
      showReadMore = false
   }

   useEffect(() => {
      setBreadCrumbData({ parents, category })
   }, [category, parents, setBreadCrumbData])

   useRedealCornerWidget()

   return (
      <>
         <CartProvider query={cartQuery}>
            {_result => {
               return (
                  <>
                     {(() => {
                        if (isBrandCategory) {
                           return (
                              <LoadableBrandsPage
                                 category={category}
                                 parents={parents}
                                 showVat={showVat}
                              />
                           )
                        } else if (isSingleBrandCategory) {
                           return (
                              <LoadableBrandPage
                                 category={category}
                                 parents={parents}
                                 showVat={showVat}
                              />
                           )
                        } else if (category?.id == 4155 || category?.id == 4027) {
                           return (
                              <LoadableNewsletterRegistrationPageV2
                                 category={category}
                              />
                           )
                        } else if (category?.id == 4161) {
                           return (
                              <LoadableNewsletterWelcomePage
                                 category={category}
                              />
                           )
                        } else if (category?.id == 4028) {
                           return (
                              <LoadableNewsletterUnregisterPage category={category} />
                           )
                        } else if (category?.parent?.parent?.id == 3820 || category?.id == 4823 || category?.id == 4430 || isWorkForUsCategory || isRestorationShowCategory ) {
                           return (
                              <LoadableBlogArticlePage
                                 category={category}
                                 parents={parents}
                              />
                           )
                        } else if (category?.parent?.id == 3820) {
                           return (
                              <LoadableCategoryBlogPage
                                 category={category}
                                 parents={parents}
                              />
                           )
                        } else if (category?.id == 3820) {
                           return (
                              <LoadableBlogPage
                                 category={category}
                                 parents={parents}
                              />
                           )
                        } else if (category?.id == 4373 || category?.parent?.id == 4373) {
                           // Borasbutiken
                           return (
                              <LoadableStorePage
                                 category={category}
                                 parents={parents}
                              />
                           )
                        } else if (category?.id == ACTIVATE_ACCOUNT_PAGE_ID) {
                           return (
                              <LoadableActivateAccountPage
                                 category={category}
                                 parents={parents}
                              />
                           )
                        } else {
                           return (
                              <LoadableCategoryStandardPage
                                 category={category}
                                 parents={parents}
                                 showVat={showVat}
                              />
                           )
                        }
                     })()}

                     {isHelloRetailAllowed && (
                        <HelloRetailWrapper>
                           <HelloRetailCarouselFetcher
                              className="py-32"
                              products={getHelloRetailProducts(
                                 helloRetailRecoms,
                                 BEST_SELLER_CATEGORY
                              )}
                              loading={helloRetailLoading}
                              title={BEST_SELLER_CATEGORY?.title}
                              showVat={showVat}
                              render={modifiedResults => <HelloRetailCarousel {...modifiedResults} />}
                           />

                           <HelloRetailCarouselFetcher
                              className="py-32"
                              products={getHelloRetailProducts(
                                 helloRetailRecoms,
                                 LATEST_VISITED
                              )}
                              loading={helloRetailLoading}
                              title={LATEST_VISITED?.title}
                              showVat={showVat}
                              render={modifiedResults => <HelloRetailCarousel {...modifiedResults} />}
                           />

                           {/* <HelloRetailCarousel
                              className="py-32"
                              products={getHelloRetailProducts(
                                 helloRetailRecoms,
                                 BEST_SELLER_CATEGORY
                              )}
                              loading={helloRetailLoading}
                              title={BEST_SELLER_CATEGORY?.title}
                           ></HelloRetailCarousel> */}

                           {/* <HelloRetailCarousel
                              className="py-32"
                              products={getHelloRetailProducts(
                                 helloRetailRecoms,
                                 LATEST_VISITED
                              )}
                              loading={helloRetailLoading}
                              title={LATEST_VISITED?.title}
                           ></HelloRetailCarousel> */}

                        </HelloRetailWrapper>
                     )}
                  </>
               )
            }}
         </CartProvider>
      </>
   )
}

export default CategoryPage
